import React from "react";
import { Box } from "@mui/material";
import { colors } from "../../utils/const";
import { styles } from "./icons_section";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import Title from "../../components/mini-components/Title.jsx";

export default function IconsSection({}) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  const findSubstringIndex = (string, substring) => {
    return string.toLowerCase().indexOf(substring.toLowerCase());
  };

  const renderString = (string) => {
    const substring = locale === "en" ? "Kol Yom" : "كل يوم";
    const index = findSubstringIndex(string, substring);
    if (index !== -1) {
      return (
        <div>
          {string.substring(0, index)}
          <strong>{string.substring(index, index + substring.length)}</strong>
          {string.substring(index + substring.length)}
        </div>
      );
    } else {
      return <div>{string}</div>;
    }
  };
  return (
    <>
      <Box className="horizontal-safe-padding  mt-5">
        <Title title={t.home_page.services_title}>
          {t.home_page.services_title}
        </Title>
      </Box>
      <Box
        sx={{ ...styles.container }}
        className="horizontal-safe-padding vertical-safe-padding mb-5"
      >
        {t.featureSection.map((item, index) => {
          return (
            <Box sx={{ ...styles.container, mt: 2 }} key={index}>
              <Box
                key={item.id}
                sx={styles.item}
                data-aos="fade-up"
                data-aos-delay={item.delay}
              >
                <Box
                  component="img"
                  src={item.img}
                  alt={item.title}
                  sx={{
                    height: { xs: "65px", md: "80px" },
                    width: { xs: "65px", md: "80px" },
                  }}
                  loading="lazy"
                ></Box>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: { xs: "0px", md: "27px" },
                    fontWeight: "bold",
                    color: colors.blue,
                  }}
                >
                  {item.title}
                </Box>
                <Box
                  sx={{
                    wordWrap: "break-word",
                    textAlign: "center",
                    ontSize: 2,
                    color: colors.petroli,
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {renderString(item.text)}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
