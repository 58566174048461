export const styles = {
  container: {
    display: "flex",
    justifyContent: { xs: "center", md: "space-between" },
    flexWrap: "wrap",
    gap: 2,
  },
  item: (theme) => ({
    textAlign: "center",
    width: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 1,
  }),
};
